import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Transition } from 'react-spring';
import classnames from 'classnames';

import { mapAssetType } from './video-utils';
import playIcon from './media/play.svg';
import style from './video-item.scss';

class VideoItem extends Component {
    static propTypes = {
        componentId: PropTypes.string.isRequired,
        showTitle: PropTypes.string,
        videoTitle: PropTypes.string,
        duration: PropTypes.string,
        thumbnail: PropTypes.string,
        date: PropTypes.string,
        rating: PropTypes.string,
        shortDescription: PropTypes.string,
        type: PropTypes.string,
        isActive: PropTypes.bool,
        handleClick: PropTypes.func.isRequired,
        settings: PropTypes.shape({
            displayVideoTitle: PropTypes.bool.isRequired,
            displayShowTitle: PropTypes.bool.isRequired,
            displayVideoDescription: PropTypes.bool.isRequired,
            displayVideoMeta: PropTypes.bool.isRequired
        }),
        isPassportVideo: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            imageLoaded: false
        };

        this.onImageLoad = this.onImageLoad.bind(this);
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.thumbnail !== nextProps.thumbnail) {
            this.setState({
                imageLoaded: false
            });
        }
    }

    onImageLoad = () => {
        this.setState({ imageLoaded: true });
    };

    render() {
        const {
            videoTitle,
            showTitle,
            thumbnail,
            duration,
            date,
            rating,
            shortDescription,
            isActive,
            handleClick,
            type,
            isPassportVideo,
            settings: {
                displayVideoTitle,
                displayShowTitle,
                displayVideoDescription,
                displayVideoMeta
            }
        } = this.props;
        const { imageLoaded } = this.state;

        const videoMeta = [mapAssetType(type), duration, date, rating]
            .filter((item) => !!item)
            .join(' | ');

        const transitionProps = {
            component: false,
            config: {
                tension: 50,
                friction: 20
            },
            enter: {
                opacity: 1
            },
            leave: {
                opacity: 0
            }
        };

        return (
            <li
                className={classnames({
                    active: isActive,
                    'video-item': true,
                    [style.videoItem]: true,
                    'passport-video': isPassportVideo
                })}>
                <a
                    href=""
                    className="thumbnail-wrapper shape-placeholder-fill"
                    onClick={handleClick}>
                    {thumbnail && (
                        <span>
                            <img
                                src={thumbnail}
                                alt={videoTitle}
                                className={classnames({
                                    'thumbnail-img': true,
                                    loaded: imageLoaded
                                })}
                                onLoad={this.onImageLoad}
                                loading="lazy"
                            />
                            <img className="play-icon" src={playIcon} alt="play" />
                        </span>
                    )}
                </a>
                <div className="video-info">
                    <Transition {...transitionProps}>
                        {() =>
                            displayShowTitle && (
                                <h4 className="text-placeholder show-title" key="show-title">
                                    {showTitle}
                                </h4>
                            )
                        }
                    </Transition>
                    <Transition {...transitionProps}>
                        {() =>
                            displayVideoTitle && (
                                <h3 className="text-placeholder video-title" key="video-title">
                                    {videoTitle}
                                </h3>
                            )
                        }
                    </Transition>
                    <Transition {...transitionProps}>
                        {() =>
                            displayVideoMeta && (
                                <div className="text-placeholder video-meta" key="video-meta">
                                    {videoMeta}
                                </div>
                            )
                        }
                    </Transition>
                    <Transition {...transitionProps}>
                        {() =>
                            displayVideoDescription && (
                                <p
                                    className="text-placeholder-long description short"
                                    key="description">
                                    {shortDescription}
                                </p>
                            )
                        }
                    </Transition>
                </div>
            </li>
        );
    }
}

export default VideoItem;
