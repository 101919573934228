import { PAGE_TYPES, SPECIAL_PAGE } from 'constants';

export const isDeletedPage = (page) => page.deleteDate;
export const isSearchPage = (page) => page.specialPageType === SPECIAL_PAGE.SEARCH;
export const isPopupRoot = (page) =>
    page.specialPageType === SPECIAL_PAGE.POPUP && page.pageType === PAGE_TYPES.DEFAULT;
export const isPopupRegular = (page) =>
    page.specialPageType === SPECIAL_PAGE.POPUP && page.pageType !== PAGE_TYPES.DEFAULT;
export const isPopupPage = (page) => page.specialPageType === SPECIAL_PAGE.POPUP;
export const isPopupCollection = (page) => page.path === 'popup-collection';
export const isPopupRelated = (page) => isPopupPage(page) || isPopupCollection(page);
export const isBlogRelated = (page) => !!(page.path && page.path.startsWith('/blogs'));
export const isSpecialtyPage = (page) => ['/404', '/500', '/search'].includes(page.path);
export const isAvailablePage = (page) => !isDeletedPage(page) && !isSpecialtyPage(page);
export const isStandardPage = (page) =>
    !isDeletedPage(page) && !isSpecialtyPage(page) && !isBlogRelated(page) && !isPopupRelated(page);
export const isBlogLandingPage = (page) => /^\/blogs$/g.test(page.path);
export const isBlogCategory = (page) => /^\/blogs\/[a-zA-Z0-9-]*$/g.test(page.path);
export const isBlogEntry = (page) => /^\/blogs\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]*$/g.test(page.path);
export const isBentomaticSourcePage = (page) => !!page.bentomatic;
export const isRootPage = (page) => page.path === '/';

export function getAvailablePages(pagesList, currentSite) {
    if (!pagesList || !currentSite) {
        return [];
    }
    const { showBlogs, showSearchPage, showPopupPage } = currentSite;

    const result = pagesList.filter((page) => {
        if (isDeletedPage(page)) {
            return false;
        }
        if (!showSearchPage && isSearchPage(page)) {
            return false;
        }
        if (!showPopupPage && isPopupRelated(page)) {
            return false;
        }

        if (!showBlogs && isBlogRelated(page)) {
            return false;
        }
        return true;
    });

    return result;
}

export function siftPages(activePageId, pages = []) {
    let result = {
        standard: [],
        special: [],
        popupCollection: []
    };
    let activePage = pages[0];

    pages.forEach((page) => {
        if (page.id === activePageId) {
            activePage = page;
        }

        if (isSpecialtyPage(page)) {
            result.special.push(page);
        }

        if (isStandardPage(page)) {
            result.standard.push(page);
        }

        if (isPopupRoot(page)) {
            result.popupCollection.splice(0, 0, page);
        }

        if (isPopupRegular(page)) {
            result.popupCollection.push(page);
        }
    });
    return {
        ...result,
        activePage
    };
}
